import React from 'react'
import gsap from 'gsap'

class Cursor extends React.Component {
  state = {
    cursorX: 0,
    cursorY: 0,
    duration: 0,
    mouseFirstMove: false,
    mouseHasMoved: false,
  }

  handleLinks = () => {
    document.querySelectorAll('a, button, select').forEach(item => {
      item.onmouseenter = () => {
        gsap.to('.cursor-a', {
          duration: 0.25,
          opacity: 0,
        })
        gsap.to('.cursor-c', {
          duration: 0.25,
          width: '72px',
          height: '72px',
          opacity: 1,
        })
      }
      item.onmouseleave = () => {
        gsap.to('.cursor-a', {
          duration: 0.25,
          opacity: 1,
        })
        gsap.to('.cursor-c', {
          duration: 0.25,
          width: '36px',
          height: '36px',
          opacity: 0.65,
        })
      }
    })
  }

  handleCustomCursorClick = () => {
    document.querySelectorAll('.handle-custom-cursor-click').forEach(item => {
      item.addEventListener('click', () => {
        setTimeout(() => {
          this.handleLinks()
        }, 500)
      })
    })
  }

  eventTick = () => {
    const {
      cursorX,
      cursorY,
      duration,
      mouseFirstMove,
      mouseHasMoved,
    } = this.state
    if (mouseFirstMove && !mouseHasMoved) {
      this.setState({
        duration: 0.5,
        mouseHasMoved: true,
      })
    }
    gsap.to('.cursor-a', {
      duration: 0,
      x: `${cursorX}px`,
      y: `${cursorY}px`,
    })
    gsap.to('.cursor-b', {
      duration,
      x: `${cursorX}px`,
      y: `${cursorY}px`,
    })
  }

  mouseMoveEvent = event => {
    const { mouseFirstMove } = this.state
    if (!mouseFirstMove) {
      this.setState({
        mouseFirstMove: true,
      })
      gsap.to('.cursor-a', {
        duration: 0.5,
        opacity: 1,
      })
      gsap.to('.cursor-b', {
        duration: 0.5,
        opacity: 1,
      })
    }
    this.setState({
      cursorX: event.x,
      cursorY: event.y,
    })
  }

  componentDidMount = () => {
    this.handleCustomCursorClick()
    this.handleLinks()
    gsap.ticker.add(this.eventTick)
    window.addEventListener('mousemove', this.mouseMoveEvent)
  }

  componentWillUnmount = () => {
    gsap.ticker.remove(this.eventTick)
    window.removeEventListener('mousemove', this.mouseMoveEvent)
  }

  render() {
    return (
      <>
        <div className="cursor-a"></div>
        <div className="cursor-b">
          <div className="cursor-c"></div>
        </div>
      </>
    )
  }
}

export default Cursor
