import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

import FutureImage from '../../images/footer/future.jpg'
import KawateaImage from '../../images/footer/kawatea.jpg'
import PotFishingImage from '../../images/footer/pot-fishing.jpg'
import OurStoryImage from '../../images/footer/our-story.jpg'
import PastImage from '../../images/footer/past.jpg'
import PresentImage from '../../images/footer/present.jpg'
import TraceabilityImage from '../../images/footer/traceability.jpg'

const FooterLink = ({ title, subtitle, image, gradient, to }) => {
  return (
    <AniLink fade to={`/${to}`}>
      <div
        className="bg-cover bg-center"
        style={{ backgroundImage: `url('${image}')` }}
      >
        <div className={gradient && 'gradient-footer-link'}>
          <div className="bg-blue-900 hover:bg-transparent transition-bg transition-800 transition-custom">
            <div className="gutters py-16 lg:py-24">
              <h5 className="type-upper-xs leading-tight mb-1">{subtitle}</h5>
              <h4 className="text-2xl uppercase font-semibold tracking-wider leading-tight">
                {title}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </AniLink>
  )
}

const FooterLinks = ({ links }) => {
  return (
    <Fade delay={225} duration={2250}>
      <div className="lg:flex">
        {links.map((item, index) => {
          return (
            <div
              key={index}
              className={`w-full lg:w-1/${links.length} border-b-3 lg:border-b-0 lg:border-r border-blue-800 last:border-0`}
            >
              {item === 'the-kawatea' && (
                <FooterLink
                  to={item}
                  title="Kawatea"
                  subtitle="Our vessel"
                  image={KawateaImage}
                  gradient
                />
              )}
              {item === 'pot-fishing' && (
                <FooterLink
                  to={item}
                  title="Pot Fishing"
                  subtitle="How it works"
                  image={PotFishingImage}
                  gradient
                />
              )}
              {item === 'traceability' && (
                <FooterLink
                  to={item}
                  title="Traceability"
                  subtitle="Responsible fishing"
                  image={TraceabilityImage}
                  gradient
                />
              )}
              {links.length === 3 && item === 'past' && (
                <FooterLink
                  to={item}
                  title="Our story"
                  subtitle="Dedication"
                  image={OurStoryImage}
                  gradient
                />
              )}
              {links.length === 2 && item === 'past' && (
                <FooterLink
                  to={item}
                  title="Past"
                  subtitle="Our story"
                  image={PastImage}
                />
              )}
              {item === 'present' && (
                <FooterLink
                  to={item}
                  title="Present"
                  subtitle="Our story"
                  image={PresentImage}
                />
              )}
              {item === 'future' && (
                <FooterLink
                  to={item}
                  title="Future"
                  subtitle="Our Story"
                  image={FutureImage}
                />
              )}
            </div>
          )
        })}
      </div>
    </Fade>
  )
}

export default FooterLinks
