import React from 'react'
import Fade from 'react-reveal/Fade'

import FooterLinks from './FooterLinks'

const Footer = ({ links }) => {
  return (
    <Fade delay={225} duration={2250}>
      <div className="w-full">
        <FooterLinks links={links} />
      </div>
    </Fade>
  )
}

export default Footer
