import React from 'react'
import Img from 'gatsby-image'
import gsap from 'gsap'
import Fade from 'react-reveal/Fade'

import ScrollLink from '../Ui/ScrollLink'
import HomeHeroText from '../Home/HomeHeroText'

class Hero extends React.Component {
  state = {
    ready: false,
  }

  eventTick = () => {
    const scrollY = window.scrollY
    const windowHeight = window.innerHeight
    const opacity = scrollY / ((windowHeight / 3) * 2)

    if (scrollY < windowHeight && scrollY > -1) {
      gsap.to('.hero__image', {
        duration: 1,
        y: -(scrollY / 4),
        z: -(scrollY / 4),
      })
      gsap.to('.hero__title', {
        duration: 1,
        y: -(scrollY / 8),
        z: -(scrollY / 6),
      })
      gsap.to('.hero__mask', {
        duration: 1,
        opacity: opacity,
      })
    }
  }

  imageLoaded = () => {
    if (this.state.ready === false) {
      this.setState({
        ready: true,
      })
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.eventScroll)
    gsap.ticker.add(this.eventTick)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.eventScroll)
    gsap.ticker.remove(this.eventTick)
  }

  render() {
    const {
      title,
      preTitle,
      isHomeHero,
      image,
      alt,
      scrollLink,
      gradient,
      caption,
    } = this.props
    return (
      <Fade duration={2250} in={this.state.ready}>
        <div className="hero">
          {scrollLink && (
            <ScrollLink
              to="discover"
              text="Discover"
              className="z-40"
              position="left"
            />
          )}
          {gradient && <div className="hero__gradient z-40"></div>}
          <div className="hero__mask z-50"></div>
          <div className="hero__title z-30">
            {isHomeHero ? (
              <>
                <h1 className="text-4xl md:text-6xl lg:text-9xl font-semibold md:font-medium uppercase tracking-wide leading-none">
                  {title}
                </h1>
                <HomeHeroText />
              </>
            ) : (
              <>
                <h2 className="text-xs uppercase font-light tracking-widest mb-1 md:mb-3 lg:mb-6">
                  {preTitle}
                </h2>
                <h1 className="text-3xl md:text-6xl lg:text-9xl font-medium uppercase tracking-wider leading-none">
                  {title}
                </h1>
              </>
            )}
          </div>
          <div className="hero__image z-10">
            <Img
              fluid={image}
              alt={alt}
              onLoad={this.imageLoaded.bind(this)}
              objectFit="cover"
              objectPosition="50% 50%"
            />
            {caption && (
              <>
                <div className="hero__caption-gradient z-10"></div>
                <div className="absolute inset-x-0 bottom-0 pb-6 z-20">
                  <p className="text-center text-sm max-w-xs sm:max-w-full mx-auto">
                    {caption}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </Fade>
    )
  }
}

export default Hero
