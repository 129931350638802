import React from 'react'
import { CSSTransition } from 'react-transition-group'

let i = 0

class HomeHeroText extends React.Component {
  items = [
    'To Sustainability',
    'To The Past',
    'To The Present',
    'To The Future',
  ]

  state = {
    active: 0,
  }

  timer = setInterval(() => {
    if (i === 3) {
      i = 0
    } else {
      i++
    }
    this.setState({ active: i })
  }, 3000)

  // componentDidMount = () => {
  //   // ..
  // }

  componentWillUnmount = () => {
    clearInterval(this.timer)
  }

  render() {
    return (
      <div className="w-full text-center mt-3">
        <div className="relative">
          {this.items.map((item, index) => {
            return (
              <div key={index} className="absolute inset-0">
                <CSSTransition
                  timeout={2300}
                  classNames="home-hero-text"
                  in={this.state.active === index}
                  unmountOnExit
                >
                  <h2 className="text-sm md:text-base font-medium uppercase tracking-wider mb-3 md:mb-6">
                    {item}
                  </h2>
                </CSSTransition>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default HomeHeroText
