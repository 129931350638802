import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Button = ({ link, linkText }) => {
  return (
    <AniLink className="button" fade to={`/${link}`}>
      {linkText ? linkText : 'Learn More'}
    </AniLink>
  )
}

export default Button
