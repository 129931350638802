import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import poster from '../../images/posters/menu.jpg'

const mapStateToProps = ({ menuOpen }) => {
  return { menuOpen }
}

const mapDispatchToProps = dispatch => {
  return { toggleMenu: () => dispatch({ type: `TOGGLE_MENU` }) }
}

const MenuGroup = ({
  title,
  items,
  closeMenu,
  notInMenu,
  className,
  fontSize,
}) => {
  const slug = title.replace(/\s+/g, '-').toLowerCase()

  const handleMouseEnter = el => {
    document.querySelectorAll('.menu__button').forEach(item => {
      item.style.opacity = 0.3
    })
    document.querySelectorAll('.menu__title').forEach(item => {
      item.style.opacity = 0.3
    })
    el.currentTarget.style.opacity = 1
    document
      .querySelectorAll(`[data-title=${el.currentTarget.dataset.group}]`)
      .forEach(item => {
        item.style.opacity = 1
      })
  }

  const handleMouseLeave = () => {
    document.querySelectorAll('.menu__button').forEach(item => {
      item.style.opacity = 1
    })
    document.querySelectorAll('.menu__title').forEach(item => {
      item.style.opacity = 1
    })
  }

  return (
    <div className={`mb-6 md:mb-2${className ? ' ' + className : ''}`}>
      <div className="menu__title-holder">
        <h3
          data-title={slug}
          className="menu__title type-upper-xs mb-3 md:mb-2"
        >
          {title}
        </h3>
      </div>
      <div className="h-px border-t border-white opacity-25 mb-3 md:hidden"></div>
      {items.map((item, index) => (
        <div
          key={index}
          className="menu__item"
          style={{ transitionDelay: `${50 * index}ms` }}
        >
          <AniLink
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            fade
            to={item[1]}
            onClick={() => {
              closeMenu()
            }}
            data-group={slug}
            activeClassName="menu__button--active"
            className={`menu__button inline-block focus:outline-none sm:text-xl mb-2 md:mb-0 md:py-3${
              fontSize ? ' ' + fontSize : ' md:text-2xl lg:text-3xl xl:text-5xl'
            }`}
          >
            {item[0]}
          </AniLink>
        </div>
      ))}
    </div>
  )
}

const Menu = ({ menuOpen, toggleMenu, notInMenu }) => {
  const [animateItems, setAnimateItems] = useState(false)
  const [animateVideo, setAnimateVideo] = useState(false)
  const menuEl = useRef(null)

  useEffect(() => {
    if (menuOpen) {
      disableBodyScroll(menuEl.current)
    } else {
      enableBodyScroll(menuEl.current)
    }
  }, [menuOpen])

  setTimeout(() => {
    setAnimateItems(true)
  }, 750)

  setTimeout(() => {
    setAnimateVideo(true)
  }, 250)

  return (
    <div
      ref={menuEl}
      className={`menu ${animateItems ? 'menu--animate-items' : ''}`}
    >
      <CSSTransition
        in={animateVideo}
        timeout={1000}
        classNames="fade"
        unmountOnExit
      >
        <div className="menu__video hidden md:block">
          <div className="absolute inset-0 z-20 bg-blue-800-opaque-25"></div>
          <div
            className="w-full h-full relative z-10"
            dangerouslySetInnerHTML={{
              __html: `
              <video
                class="w-full h-full object-cover"
                muted
                autoplay
                playsinline
                loop
                poster="${poster}"
                src="https://player.vimeo.com/external/368694919.hd.mp4?s=a9c96f6ac404537ffcae23098ab43fe382c3b7bd&profile_id=174"
              />`,
            }}
          />
        </div>
      </CSSTransition>

      <div className="menu__main">
        <div className="md:flex md:flex-grow">
          <div className="menu__column">
            <div className="h-full flex flex-col justify-center md:text-center">
              <MenuGroup
                notInMenu={notInMenu}
                closeMenu={toggleMenu}
                title={'Our Story'}
                items={[
                  ['Past', '/past'],
                  ['Present', '/present'],
                  ['Future', '/future'],
                ]}
              />
            </div>
          </div>
          <div className="menu__column">
            <div className="h-full flex flex-col justify-center md:text-center">
              <MenuGroup
                notInMenu={notInMenu}
                closeMenu={toggleMenu}
                title={'Our Vessel'}
                items={[
                  ['The Kawatea', '/the-kawatea'],
                  ['Traceability', '/traceability'],
                  ['Pot Fishing', '/pot-fishing'],
                ]}
              />
            </div>
          </div>
          <div className="menu__column">
            <div className="h-full flex flex-col justify-center md:text-center">
              <MenuGroup
                notInMenu={notInMenu}
                closeMenu={toggleMenu}
                title={'Our Product'}
                items={[['Our Catch', '/our-catch']]}
              />
              <MenuGroup
                notInMenu={notInMenu}
                closeMenu={toggleMenu}
                title={'Company'}
                items={[['Contact', '/contact']]}
              />
              <MenuGroup
                className="md:hidden"
                notInMenu={notInMenu}
                closeMenu={toggleMenu}
                title={'Appendix'}
                items={[['Te Reo Glossary', '/glossary']]}
              />
            </div>
          </div>
        </div>
        <div className="h-44 hidden md:flex">
          <div className="menu__column"></div>
          <div className="menu__column">
            <div className="h-full flex flex-col justify-start md:text-center">
              <MenuGroup
                fontSize="md:text-xl lg:text-2xl"
                notInMenu={notInMenu}
                closeMenu={toggleMenu}
                title={'Appendix'}
                items={[['Te Reo Glossary', '/glossary']]}
              />
            </div>
          </div>
          <div className="menu__column"></div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu)
