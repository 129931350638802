import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import LogoType from '../SVG/Logos/LogoType'
import HeaderBurger from './HeaderBurger'

const mapStateToProps = ({ menuOpen }) => {
  return { menuOpen }
}

const mapDispatchToProps = dispatch => {
  return { toggleMenu: () => dispatch({ type: `TOGGLE_MENU` }) }
}

class Header extends React.Component {
  state = {
    showLogo: true,
  }

  handleClick = () => {
    const { menuOpen } = this.props
    if (menuOpen) {
      this.props.toggleMenu()
    }
  }

  eventScroll = () => {
    const { showLogo } = this.state
    let scrollY = window.scrollY
    let windowHeight = window.innerHeight
    if (scrollY >= windowHeight / 4 && showLogo === true) {
      this.setState({
        showLogo: false,
      })
    }
    if (scrollY < windowHeight / 4 && showLogo === false) {
      this.setState({
        showLogo: true,
      })
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.eventScroll)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.eventScroll)
  }

  render() {
    const { showLogo } = this.state
    const { menuOpen } = this.props

    let _showLogo
    if (menuOpen) {
      _showLogo = false
    } else if (showLogo) {
      _showLogo = true
    }

    return (
      <header className="header pointer-events-none gutters">
        <CSSTransition
          in={_showLogo}
          timeout={1000}
          classNames="logo-fade"
          // unmountOnExit
        >
          <div className="hidden sm:flex absolute inset-0 items-center justify-center z-10">
            <AniLink
              fade
              className="block pointer-events-auto fill-current w-56 h-10"
              to="/"
            >
              <LogoType />
            </AniLink>
          </div>
        </CSSTransition>

        <div className="flex h-full items-center justify-between sm:justify-end">
          <div className="block sm:hidden relative z-20">
            <AniLink
              fade
              className="block pointer-events-auto fill-current w-32 h-auto"
              to="/"
              onClick={this.handleClick}
            >
              <LogoType />
            </AniLink>
          </div>
          <div className="relative z-20">
            <HeaderBurger />
          </div>
        </div>
      </header>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
