import React from 'react'

import ArrowIcon from '../SVG/Icons/IconArrowDownCircle.js'

const ScrollLink = ({ to, text, className, position }) => {
  // reference for this code..
  // https://gist.github.com/james2doyle/5694700#file-scrollto-js

  Math.easeInOutQuad = function(t, b, c, d) {
    t /= d / 2
    if (t < 1) {
      return (c / 2) * t * t + b
    }
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  const scrollTo = () => {
    let offsetTop
    if (isNaN(to)) {
      const el = document.getElementById(to)
      offsetTop = el.offsetTop
    } else {
      offsetTop = to
    }

    const move = amount => {
      document.documentElement.scrollTop = amount
      document.body.parentNode.scrollTop = amount
      document.body.scrollTop = amount
    }

    const position = () => {
      return (
        document.documentElement.scrollTop ||
        document.body.parentNode.scrollTop ||
        document.body.scrollTop
      )
    }

    let start = position()
    let change = offsetTop - start
    let currentTime = 0
    let increment = 20
    let duration = 1000

    let animateScroll = () => {
      currentTime += increment
      var val = Math.easeInOutQuad(currentTime, start, change, duration)
      move(val)
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll)
      }
    }
    animateScroll()
  }

  return (
    <div
      className={`
        scroll-link hidden md:block ${!className ? '' : className}
        ${position === 'right' ? ' scroll-link--right' : ''}
        ${position === 'left' ? ' scroll-link--left' : ''}
      `}
    >
      <button
        onClick={() => {
          scrollTo()
        }}
        className="scroll-link__button text-xs tracking-widest uppercase focus:outline-none"
      >
        <span className="scroll-link__text">{text}</span>
        <div className="w-6 h-6 fill-current">
          <ArrowIcon />
        </div>
      </button>
    </div>
  )
}

export default ScrollLink
