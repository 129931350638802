import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = ({ menuOpen }) => {
  return { menuOpen }
}

const mapDispatchToProps = dispatch => {
  return { toggleMenu: () => dispatch({ type: `TOGGLE_MENU` }) }
}

const HeaderBurger = ({ siteTitle, menuOpen, toggleMenu }) => {
  const activeClass = () => {
    return menuOpen ? 'burger--is-active' : 'burger--is-not-active'
  }

  return (
    <button
      className={`burger handle-custom-cursor-click pointer-events-auto ${activeClass()}`}
      onClick={toggleMenu}
    >
      <div className="burger__el burger__el--a"></div>
      <div className="burger__el burger__el--b"></div>
    </button>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBurger)
