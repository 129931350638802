import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { BrowserView } from 'react-device-detect'

import Header from '../Header'
import Menu from '../Menu'
import Cursor from '../Cursor'
// import HelperGrid from '../Helper/HelperGrid'

import '../../styles/index.css'

const mapStateToProps = ({ menuOpen }) => {
  return { menuOpen }
}

class Layout extends React.Component {
  state = {
    checkForWindow: false,
  }

  setVhProperty = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  setScrollYProperty = () => {
    const scrollY = window.scrollY
    document.documentElement.style.setProperty('--scroll-y', `${scrollY}px`)
  }

  setDocumentProperty = () => {
    const documentPosition =
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
      100
    document.documentElement.style.setProperty('--dp', `${documentPosition}%`)
  }

  resizeEvent = () => {
    this.setVhProperty()
  }

  scrollEvent = () => {
    this.setScrollYProperty()
    this.setDocumentProperty()
  }

  componentDidMount = () => {
    this.setState({
      checkForWindow: typeof window !== 'undefined',
    })
    this.setVhProperty()
    window.addEventListener('resize', this.resizeEvent)
    window.addEventListener('scroll', this.scrollEvent)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeEvent)
    window.removeEventListener('scroll', this.scrollEvent)
  }

  render() {
    const { children, menuOpen, notInMenu } = this.props
    const { checkForWindow } = this.state
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            {/* <HelperGrid /> */}
            <CSSTransition
              in={menuOpen}
              timeout={1000}
              classNames="fade"
              unmountOnExit
            >
              <Menu notInMenu={notInMenu} />
            </CSSTransition>
            <Header siteTitle={data.site.siteMetadata.title} />
            {checkForWindow && (
              <BrowserView>
                <Cursor />
              </BrowserView>
            )}
            <div className="layout">{children}</div>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(mapStateToProps)(Layout)
