import React from 'react'

const IconArrowDownCircle = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 23 23">
      <path d="M11.5,23A11.5,11.5,0,1,1,23,11.5,11.51,11.51,0,0,1,11.5,23Zm0-22A10.5,10.5,0,1,0,22,11.5,10.51,10.51,0,0,0,11.5,1Zm5.39,8.76-.73-.68L11.5,14,7,9.08l-.74.68L11.5,15.5Z" />
    </svg>
  )
}

export default IconArrowDownCircle
